export enum EModuleCode {
  AttendanceManagement = "AM",
  CompanySetup = "CS",
  EmployeeServices = "ES",
  HRAssistant = "HA",
  PeopleCentral = "PC",
  SystemConfiguration = "SC",
  UserManagement = "UM",
  PayrollSetup = "PR",
}

export enum EModuleFeatureCode {
  // company setup
  company = "COM",
  companyBranch = "COB",
  department = "DEP",
  job = "JOB",
  position = "POS",

  // system configuration
  permissionType = "PET",
  leaveType = "LET",
  workflow = "WOF",

  // people central
  employee = "EMP",

  // hr assistant
  companyFile = "COF",

  // employee services
  leave = "LEA",
  leaveReq = "LER",
  permission = "PEN",
  permissionReq = "PER",
  workFromHomeReq = "WFH",
  BusinessMission = "BME",
  BusinessMissionRequest = "BMR",
  WorkOnVacationOrder = "WOV",
  WorkOnVacationOrderRequest = "WVR",
  WorkOnVacationConsideration = "WVCE",
  WorkOnVacationConsiderationRequest = "WVC",
  OvertimeAmountSubmission = "OAS",
  OvertimeAmountSubmissionRequest = "OAR",

  // attendance management
  eligibility = "ELI",
  rotationGroup = "ROG",
  workSchedule = "WOS",
  yearlyHoliday = "YEH",
  user = "USE",
  policy = "POL",
  attendance = "ATT",
  attendanceTransaction = "ATR",

  // payroll setup
  Bank = "BNK",
  ElementBatch = "ELB",
  EmployeeBatch = "EMB",
  ExchangeRate = "EXR",
  PayrollDefinition = "PRL",
  PayrollElement = "ELM",
  PayrollRun = "PRR",
  SalaryStructure = "SST",
  SocialInsurance = "SoI",
  TaxRule = "TXR",
}
